import { AppBar, IconButton, Toolbar, Tabs, Tab, Button, useTheme, useMediaQuery, Typography } from '@mui/material'
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import React, { useState } from 'react'
import './nav.css'
import Drawercomp from '../Drawer/Drawer';
import { Link } from 'react-router-dom';


export default function Nav() {

  const PAGES = ['About', 'Products', 'Contact'];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment >
      <AppBar
        sx={{ backgroundColor: '#402E32' }}
        position="static"
      >
        <Toolbar className='navbar'>
          <IconButton href='/'><span className='logo'><CoffeeMakerIcon /> Coffee Shop</span></IconButton>
          {
            matches ? (
              <>

                <Drawercomp />
              </>
            ) : (
              <>
                <Tabs
                  sx={{ margin: 'auto', color: 'antiquewhite' }}
                  textColor='antiquewhite'
                >
                  {
                    PAGES.map((page, index) => (
                      <Tab key={index} label={page} href={`/${page}`}>
                      </Tab>
                    ))
                  }

                </Tabs>

                <Button variant='outlined'
                  sx={{
                    color: 'antiquewhite', border: '1px solid',
                    "&:hover": { backgroundColor: 'antiquewhite', color: '#402E32', border: 'none' },
                    borderRadius: '15px'

                  }}
                  href='/Login'
                >
                  Login
                </Button>

                <Button className='Register' variant='contained'
                  sx={{
                    color: '#402E32',
                    border: '1px solid',
                    marginLeft: '10px',
                    backgroundColor: 'antiquewhite',
                    "&:hover": { backgroundColor: '#402E32', color: 'antiquewhite', border: '1px solid' },
                    borderRadius: '15px'
                    
                  }}
                  href='/Signup'
                >Sign up</Button>
              </>
            )

          }

        </Toolbar>


      </AppBar>
    </React.Fragment>
  )
}
