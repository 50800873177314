import { Card, CardActionArea, CardActions, CardMedia, Grid, Paper, Typography, useTheme, useMediaQuery, Link } from '@mui/material'
import React from 'react'
import './Prod.css'
import SB from './../../components/Media/SBBG.jpg'
import DD from './../../components/Media/DDBG.jpg'
import CB from './../../components/Media/CBG.png'




export default function Prod() {
const theme = useTheme();
const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <React.Fragment>
        <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'center'}}>Choose the brand</Typography>
{
  matches?(
    <>
      <Grid container>
        <Grid item xs={4}>
          <Card sx={{ maxWidth: 365, marginTop: '100px', marginLeft: '70px' }}>
            <CardActionArea>
              <Link href='/Starbucks'>
              <CardMedia
                component='img'
                height={200}
                image={SB}
                alt='Starbucks-Beans'
               
             />
             </Link>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card sx={{ maxWidth: 365, marginTop: '100px', marginLeft: '70px' }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height={200}
                image={DD}
                alt='Dunkin-Donuts-Beans'
              />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ maxWidth: 365, marginTop: '100px', marginLeft: '70px' }}>
            <CardActionArea>
              <CardMedia
                sx={{objectFit: 'contain'
              }}
                component='img'
                height={200}
                image={CB}
                alt='Castello-Beans'
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid> 
    </>
  ):(
    <>
    <Card sx={{ maxWidth: 340, marginTop: '50px', marginLeft: '25px' }}>
            <CardActionArea>
            <Link href='/Starbucks'>
              <CardMedia
                component='img'
                height={200}
                image={SB}
                alt='Starbucks-Beans'
              />
              </Link>
            </CardActionArea>
            
          </Card>
    <Card sx={{ maxWidth: 340, marginTop: '30px', marginLeft: '25px' }}>
            <CardActionArea>
              <CardMedia
                component='img'
                height={200}
                image={DD}
                alt='Starbucks-Beans'
              />
            </CardActionArea>
          </Card>
    <Card sx={{ maxWidth: 340, marginTop: '30px', marginLeft: '25px' }}>
            <CardActionArea>
              <CardMedia
              sx={{objectFit: 'contain'
            }}
                component='img'
                height={200}
                image={CB}
                alt='Starbucks-Beans'
              />
            </CardActionArea>
          </Card>
    </>
  )
}

    </React.Fragment>
  )
}


