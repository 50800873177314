import { Card, CardActionArea, CardActions, CardMedia, Grid, Paper, Typography, useTheme, useMediaQuery, Button, CardContent } from '@mui/material'
import React, { useState } from 'react'
import CC from './../../components/Media/Starbucks/CostaRica.jpg'
import HV from './../../components/Media/Starbucks/HoneyVanilla.jpg'
import PP from './../../components/Media/Starbucks/PikePlace.jpg'
import SB from './../../components/Media/Starbucks/SpringBlind.jpg'
import CV from './../../components/Media/Starbucks/CoffeeVerona.jpg'
import HB from './../../components/Media/Starbucks/HouseBlend.jpg'
import HD from './../../components/Media/Starbucks/Holidayblend.jpg'
import VB from './../../components/Media/Starbucks/VerandaBlend.jpg'
import E from './../../components/Media/Starbucks/Espresso.jpg'
import IR from './../../components/Media/Starbucks/ItalianRost.jpg'
import B from './../../components/Media/Starbucks/Brazil.jpg'
import K from './../../components/Media/Starbucks/Kenya.png'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
export default function Startbucks() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [count, setCount] = useState(0);


      return (
        <React.Fragment>
            <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'center'}}>Starbucks products</Typography>
    {
      matches?(
        <>
          <Grid container>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px' }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height={300}
                    image={CC}
                    alt='Starbucks-Beans'
                  />

                </CardActionArea> 
                                 
                <CardContent> 
                  Add costa rica beans to cart 
                    <AddIcon onClick={()=> setCount(count + 1)}
                    sx={{marginLeft: 4,marginRight: 2, cursor: 'pointer'}}
                    >Click me</AddIcon>
                    {count}
                    <RemoveIcon onClick={()=> setCount(count - 1)}
                    sx={{marginLeft: 2, cursor: 'pointer'}}
                    >Click me</RemoveIcon>
                  </CardContent>
              </Card>
            </Grid>
    
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px' }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height={300}
                    image={HV}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={PP}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>


            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={SB}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={CV}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={HB}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>


             <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={HD}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
           <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={VB}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
             <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={E}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={IR}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>


            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={B}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 365, marginTop: '50px', marginLeft: '70px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                    sx={{
                  }}
                    component='img'
                    height={300}
                    image={K}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
            </Grid> 


          </Grid> 
        </>



      ):(
        <>
        <Card sx={{ maxWidth: 340, marginTop: '50px', marginLeft: '25px' }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height={300}
                    image={CC}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
                <CardContent> 
                  Add costa rica beans to cart 
                    <AddIcon onClick={()=> setCount(count + 1)}
                    sx={{marginLeft: 4,marginRight: 2, cursor: 'pointer'}}
                    >Click me</AddIcon>
                    {count}
                    <RemoveIcon onClick={()=> setCount(count - 1)}
                    sx={{marginLeft: 2, cursor: 'pointer'}}
                    >Click me</RemoveIcon>
                  </CardContent>
              </Card>
        <Card sx={{ maxWidth: 340, marginTop: '30px', marginLeft: '25px' }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height={300}
                    image={HV}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
        <Card sx={{ maxWidth: 340, marginTop: '30px', marginLeft: '25px', marginBottom: '30px' }}>
                <CardActionArea>
                  <CardMedia
                  sx={{
                }}
                    component='img'
                    height={300}
                    image={PP}
                    alt='Starbucks-Beans'
                  />
                </CardActionArea>
              </Card>
        </>
      )
    }
    
        </React.Fragment>
      )
}
