import { Button, makeStyles, Paper, Typography } from '@mui/material'
import React from 'react'
import About from '../About/About'
import './Home.css'
import EastIcon from '@mui/icons-material/East';


export default function Home() {
  return (
    <React.Fragment>
      <Paper className='hero'>
        <Typography className='text-h5' variant='h5'>
          Coffee
        </Typography>
        <Typography className='text-h6'>
         Coffee is a drink prepared from roasted coffee,<br/>
         grown in more than 70 countries. North and South America, <br/>
         East Asia and the Indian Subcontinent.
        </Typography>
        <Button
        sx={{
          color: '#000',
          border: '1px solid',
          backgroundColor: '#f7f7f7',
          "&:hover": { backgroundColor: '#dfaf7323', color: '#000', border: '1px solid' },
          borderRadius: '15px'                    
        }} 
        variant='outlined'
        className='btn-home'
        href='/Products'
        >Products <EastIcon sx={{marginLeft: '7px'}}/>
        </Button>

      </Paper>
      <About id='About'/>
    </React.Fragment>
  )
}
