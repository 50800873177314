import React, { useState } from 'react'
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';

export default function Drawercomp() {
    const [open, setOpen] = useState(false);
    const PAGES = ['About', 'Products', 'Contact', 'Login', 'Sign up'];

    return (

        <React.Fragment >
            <Drawer open={open}
                onClose={() => {
                    setOpen(false)
                }}

            >
                <List
                
                >
                    {
                        PAGES.map((page, index) => (
                            <ListItemButton onClick={() => setOpen(false)} key={index} href={`/${page}`}
                            >
                                <ListItemIcon>
                                    <ListItemText
                                    sx={{
                                        color: '#402E32',
                                        fontWeight: '800',
                                    }}
                                    >{page}</ListItemText>
                                </ListItemIcon>
                            </ListItemButton>
                        ))
                    }

                </List>
            </Drawer>
            <IconButton
                sx={{
                    marginLeft: 'auto',
                    color: 'antiquewhite'
                }}
                onClick={() => setOpen(!open)}>
                <MenuIcon />
            </IconButton>

        </React.Fragment>
    )
}
