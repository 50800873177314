import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'

export default function Reg() {
  return (
    <>
<Grid container>
    <Grid item sx={4}>
    <Box sx={{        
      marginLeft: '30vh',
      marginTop: '80px',
      width: 800,
      height: 500,
      backgroundColor: '#f6e2c2',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      }}>
        
    <TextField
      sx={{textAlign: 'center', padding:'18% 20% 3% 20%', width:'100%',}}
      hiddenLabel
      id="filled-hidden-label-normal"
      placeholder='Username'
      variant="outlined"
      name='username' type='text'
    />
 
    <TextField
      sx={{textAlign: 'center', padding:'0% 20% 3% 20%', width:'100%',}}
      hiddenLabel
      id="filled-hidden-label-normal"
      placeholder='Email'
      variant="outlined"
      name='email' type='email'
    />

    <TextField
      sx={{textAlign: 'center', padding:'0% 20% 0% 20%', width:'100%', color: '#f6e2c2'}}
      hiddenLabel
      id="filled-hidden-label-normal"
      placeholder='Password'
      variant="outlined"
      name='password' type='password'
    />

    <Button variant="contained" type="submit" value="Send"
    sx={{textAlign: 'center', marginLeft: '20%', marginTop: '20px', backgroundColor:'#402e32', color:'#f6e2c2', width:'15%',
  '&:hover':{backgroundColor: '#402e32'}
  }}
    >Sign up</Button>

    </Box>
    </Grid>

    <Grid item sx={4}>
    <Box sx={{        
      marginLeft: '0vh',
      marginTop: '80px',
      width: 300,
      height: 500,
      backgroundColor: '#402e32',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      }}>
      <Typography mb={2} sx={{textAlign: 'left', padding:'60% 0% 0% 30%', color: '#f6e2c2', fontSize: '30px'}}
      >Welcome,</Typography>

      <Typography sx={{textAlign: 'left', padding:'0% 0% 50% 5%', color: '#f6e2c2', fontSize: '30px'}}
      >Create new account</Typography>
    </Box></Grid>

    </Grid>
</>

  )
}
