import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Espresso from '../../components/Media/Espresso2.jpg'
import Latte from '../../components/Media/Latte2.jpg'
import Mocha from '../../components/Media/Mocha2.jpg'
import CR from '../../components/Media/Starbucks/CostaRica.jpg'
import PP from '../../components/Media/Starbucks/PikePlace.jpg'
import HV from '../../components/Media/Starbucks/HoneyVanilla.jpg'



export default function About() {
    return (
        <>
        <section class='/About' id='/About'>
        <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'center'}}>Coffees make you happy</Typography>
            <Grid container>
                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px' }}>
                        <CardMedia
                            component='img'
                            height={200}
                            image={Espresso}
                            alt={'Espresso'}
                        />
                    </Card>
                </Grid>

                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px' }}>
                        <CardMedia
                            component='img'
                            height={200}
                            image={Latte}
                            alt={'Latte'}
                        />
                    </Card>
                </Grid>

                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px', }}>
                        <CardMedia
                            component='img'
                            height={200}
                            image={Mocha}
                            alt={'Mocha'}
                        />
                    </Card>
                </Grid>
            </Grid>
        </section>
        <section>
            <Typography variant='h4' component='h2' mt={20} mb={3} sx={{textAlign: 'center'}}>StarBucks Products</Typography>

            <Grid container>
            <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px', }}>
                        <CardMedia
                            component='img'
                            height={400}
                            image={CR}
                            alt={'CostaRica'}
                        />
                    </Card>
                </Grid>
            <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px', }}>
                        <CardMedia
                            component='img'
                            height={400}
                            image={PP}
                            alt={'PikePlace'}
                        />
                    </Card>
                </Grid>
            <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345, margin: '20px 0px 20px 70px', }}>
                        <CardMedia
                            component='img'
                            height={400}
                            image={HV}
                            alt={'HoneyVanilla'}
                        />
                    </Card>
                </Grid>
            </Grid>

            
        </section>
            
        </>

    )
}
