import { TextField, Typography, Box, Grid, Button } from '@mui/material'
import React from 'react'
import  './Login.css'

export default function Login() {
  return (
<>
<Grid container>
  <Grid item sx={4}>
    <Box sx={{        
      marginLeft: '30vh',
      marginTop: '80px',
      width: 300,
      height: 500,
      backgroundColor: '#402e32',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      }}>
      <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'left', padding:'70% 0% 0% 34%', color: '#f6e2c2'}}
      >Login</Typography>
      <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'left', padding:'0% 0% 50% 20%', color: '#f6e2c2'}}
      >Welcome again</Typography>
    </Box></Grid>

    <Grid item sx={4}>
    <Box sx={{        
      marginLeft: '0vh',
      marginTop: '80px',
      width: 800,
      height: 500,
      backgroundColor: '#f6e2c2',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      }}>
        
    <TextField
      sx={{textAlign: 'center', padding:'20% 20% 3% 20%', width:'100%',}}
      hiddenLabel
      id="filled-hidden-label-normal"
      placeholder='Email'
      variant="outlined"
    />

    <TextField
      sx={{textAlign: 'center', padding:'0% 20% 0% 20%', width:'100%', color: '#f6e2c2'}}
      hiddenLabel
      id="filled-hidden-label-normal"
      placeholder='Password'
      variant="outlined"
    />

    <Button variant="contained"
    sx={{textAlign: 'center', marginLeft: '20%', marginTop: '20px', backgroundColor:'#402e32', color:'#f6e2c2', width:'15%',
  '&:hover':{backgroundColor: '#402e32'}
  }}
    >Login</Button>

    </Box>
    </Grid>
    </Grid>
</>
  )
}
