import { Typography, TextField, TextareaAutosize, Button } from '@mui/material'
import './Cont.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Cont() {

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_dahmF15', 'template_dahmF15', form.current, 'F4nXiCt8ORGaXD2FL')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  }


  return (
    <>
    <form className='form-contact'  ref={form} onSubmit={sendEmail}>

    <Typography variant='h4' component='h2' mt={3} mb={3} sx={{textAlign: 'center'}}>Contact Us</Typography>
    

    <TextField id="standard-basic" label="Name" variant="standard"  name='name' type='text'
    sx={{marginLeft: '300px', marginTop:' 10px'}}
    />


    <TextField id="standard-basic" label="Email" variant="standard" name='email' type='email'
    sx={{marginLeft: '300px', marginTop:' 10px'}}
    />


    <TextField id="standard-basic" label="Subject" variant="standard" type="text" name="subject"
    sx={{marginLeft: '300px', marginTop:' 10px', color: 'black important'}}
    />

    <TextareaAutosize
      aria-label="minimum height"
      minRows={10}
      placeholder="Write your message"
      type="text"
      name="message"
      style={{ width: 400, display: 'flex', marginLeft: '200px', marginTop: '20px', backgroundColor: '#fff2df', paddingLeft: '10px'}}
    />
    <Button variant="outlined" type="submit" value="Send"
    sx={{marginLeft: '350px', marginTop: '20px', color: 'black', border: '1px solid black'}}
    >Send</Button>
    </form>
  </>
    )
}
