import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Nav from './components/Nav/nav'
import React from 'react';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Cont from './pages/Contact/Cont';
import Login from './pages/Login/Login';
import Register from './pages/Reg/Reg';
import Prod from './pages/Products/Prod';
import Star from './pages/Starbucks/Starbucks';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <>
  <React.Fragment>
  <Nav/>

  <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Cont />} />
        <Route path="Signup" element={<Register />} />
        <Route path="Login" element={<Login />} />
        <Route path="Products" element={<Prod />} />
        <Route path="Starbucks" element={<Star />} />
      </Routes>
    </Router>

{/* <Footer/> */}
</React.Fragment>
    </>
  );
}

export default App;
